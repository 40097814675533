import React from 'react'


export default class Footer extends React.Component {
    render(){
        return (
            <footer className="dark">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 adress">
                            <p><b className="red">Адрес:</b></p>
                            <p>23100, Москва,</p>
                            <p>1-ый Красногвардейский проезд, д.15, </p>
                            <p>Меркурий Сити Тауэр</p>
                        </div>
                        <div className="col-lg-3">
                           <p> <b className="red">Телефон:</b> <a href="tel:+74952233337">+7 495 223-33-37</a></p>
                           <p> <b className="red">Факс:</b> <a href="tel:+74959330259">+7 495 933-02-59</a></p>
                           <p> <b className="red">Горячая линия:</b> <a href="tel:+78003330201">+7 800 333-02-01</a></p>
                        </div>
                        <div className="col-lg-5">
                           <p>© Дикси 2005-2020. Все права защищены</p>
                           <p>«АО «ДИКСИ Групп» раскрывает информацию в соответствии 
                               с требованиями законодательства на настоящем сайте и на странице 
                               в сети Интернет ООО&nbsp;"Интерфакс-ЦРКИ"</p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
import React from 'react'
import Name from './Name'
import Text from './TextInner'
import Phone from '../img/phone-2.jpg'
import Google from '../img/google-pic.svg'
import Ios from '../img/ios-app.png'

const iOSUrl = 'https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8';
const Txt = ['Приложение доступно для скачивания в AppStore и Google Play. ']
export default class Download extends React.Component {
    render(){
        return (
            <div className='section download' id="downloadLink">
                <div className="container">
                    <div className="row">
                       <div className="col-lg-4">
                            <img src={Phone} alt="f" />
                       </div>
                       <div className="col-lg-7 with-txt">
                            <Name text='Где скачать?' classes='red' />
                            <Text text={Txt} classes='blue'/>
                            <div className="row links">
                                <div className="col-md-auto">
                                   <a href="https://play.google.com/store/apps/details?id=com.smartsatu.pervimdelom">
                                       <img src={Google} alt="Google link" />
                                   </a>
                                </div>
                                <div className="col-md-auto">
                                   <a href="https://apps.apple.com/app/id1506213438">
                                       <img src={Ios} alt="Ios link" />
                                   </a>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        )
    }
}
import React from 'react';
import Welcome from './components/Welcome'
import Profits from './components/Profits'
import More from './components/More'
import Mechanics from './components/Mechanics'
import Footer from './components/Footer'
import Download from './components/Download'
import About from './components/About'
import Waiting from './components/Waiting'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'


class App extends React.Component {
	render() {
		return (
			<div className="content">
				<Welcome />
				<About />
				<Profits />
				<Waiting/>
				<Mechanics />
				<Download />
				<More />
				<Footer />
			</div>
		);
	}
}

export default App;

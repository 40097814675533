import React from 'react'
import  Name  from "./Name";
import Text from './TextInner'
import Phone from '../img/phone-1.jpg'

const InnerTxt = ['«Первым делом» – это возможность заказывать товары по лучшим оптовым ценам в любое удобное для вас время. А ещё, первыми узнавать об актуальных ценах, регулярных акциях и расширении ассортимента.  ']

export default class About extends React.Component {
    render(){
        return (
            <div className='section about'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <img src={Phone} alt="phone" />
                        </div>
                        <div className='col-lg-7'>
                            {/* <Name text = 'Что такое мобильное приложение «Первым делом»' classes='blue' /> */}
                            <h2 class='blue'>Что такое мобильное приложение <span className="red">«Первым делом»</span></h2>
                            <Text text ={ InnerTxt } classes='blue' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
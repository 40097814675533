import React from 'react'
import Name from './Name'
import LogoDixy from '../img/Logo_Dixy_vertical.svg'
import LogoPervym from "../img/LogoPervym.png";
import ClassicButton from './ClassicButton'



export default class Welcome extends React.Component {
    render(){
        return (
            <div className='section welcome'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="logos row">
                                <div className="col-md-3 offset-1">
                                    <a href="http://dixy.ru/" target="_blank"><img src={LogoDixy} alt="LogoDixy" /></a>
                                </div>
                                <div className="col-md-4 offset">
                                    <a href="https://group.dixy.ru/partners/franshiza-pervim-delom/" target="_blank"><img src={LogoPervym} alt="LogoDixy" /></a>
                                </div>

                            </div>
                           <h1 className="blue">Удобная <b className="red">франшиза</b> в мобильном приложении</h1>
                            <ClassicButton text='Скачать приложение' link="#downloadLink"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
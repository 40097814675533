import React from 'react'

const classes = ['blue', 'red']

export default class Name extends React.Component {
  render () {
        return (
    <h2 className={this.props.classes} >{this.props.text}</h2>
    )
  }
}
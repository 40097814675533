import React from 'react'
import Name from './Name'
import Text from './TextInner'
import Pic from '../img/how-it-works-pic.svg'
import ClassicButton from './ClassicButton'
import WPic from '../img/waiting.jpg'


const text = [
   [
    'Поднесите телефон к штрих-коду и товар сразу в вашей корзине или выберите товары в каталоге'],
   [ 'Чат поддержки',
    'Пишите срочные вопросы по ассортименту, ценам и доставке в чат поддержки'],
    ['Web-интерфейс',
    'Заходите в личный кабинет не только со смартфона, но и с компьютера']
]

export default class Waiting extends React.Component {
    render(){
        return (
            <div className='section'>
                <div className="container">
                    <div className='row'>
                    <div className="col-lg-7">
                            <img src={WPic} alt="img" />
                        </div>
                        <div className="col-lg-5">
                            <Name text= 'Больше не надо ждать торгового представителя' classes='red' />
                            <p className='blue'>
                                <b className="red">Сбор заказа за 10 минут</b><br/>
                                Поднесите телефон к штрих-коду и товар сразу в вашей корзине или выберите товары в каталоге
                            </p>
                            <p className='blue'>
                                <b className="red">Чат поддержки</b><br/>
                                Пишите срочные вопросы по ассортименту, ценам и доставке в чат поддержки
                            </p>
                            <p className='blue'>
                                <b className="red">Web-интерфейс</b><br/>
                                Заходите в личный кабинет не только со смартфона, но и с компьютера
                            </p>
                            < ClassicButton text='перейти в web-интерфейс' link="https://pd.dixy.ru/"/>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}
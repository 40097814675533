import React from 'react'
import Name from './Name'
import Text from './TextInner'
import House from '../img/house.jpg'


const Txt = [
    'Франшиза «Первым делом» - это  уникальный  проект «Дикси» основанный в марте 2019г. для развития вашего бизнеса и создания современного  формата магазина «у дома». Вы получите ряд преимуществ: известный бренд, лучшие цены, широкий ассортимент  и  опыт продаж крупной сети. ', 
    'Наша миссия: развивать успешный и эффективный  бизнес, предлагая нашим клиентам оптимальный  ассортимент качественных товаров по низким ценам при высоком уровне сервиса и минимальных затратах наших партнеров.'
]

export default class More extends React.Component {
    render(){
        return (
            <div className='section more'>
               <div className="container">
                   <div className="row">
                       <div className="col-lg-6">
                            <Name text='Подробнее о франшизе' classes='red' />
                            <Text text = {Txt} classes="blue"/>
                            <b><a className="blue" href="https://drive.google.com/file/d/1rXGRlpi9WJo2UXUs9c-A9HJn3-AgcS1b/view" target="_blank">Презентация франшизы «Первым делом»</a></b>
                       </div>
                       <div className="col-lg-6">
                            <img src={House} alt="more" />
                       </div>
                   </div>
               </div>
            </div>
        )
    }
}
import React from 'react'


export default class Text extends React.Component{
    render(){
        const Textes =  this.props.text
        const Ins = this.props.insert
        return (
            <>
            {Textes.map((item, index) => (
                <p className={this.props.classes} key={index}>
                    <b className="red">{Ins} </b>
                    {item}
                    </p>
                )
            )}
            </>
        )
            
    }
}
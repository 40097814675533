import React from 'react'
import Name from './Name'
import Text from './TextInner'
import Pic from '../img/phone-from-3.png'

const frst = [
    <b>Во-первых, это удобно:</b>
]
const Txt = [ 
             'Регистрируетесь  в приложении.',
             'Если зашли впервые – заключаете договор (это просто).',
             'Закупаетесь онлайн в каталогах.',
             'Ожидаете доставку уже на следующий день!',
             'В любой непонятной ситуации обращаетесь в чат-поддержку.'
            ]

export default class Mechanics extends React.Component {
    render(){
        return (
            <div className='section gray'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-6">
                            <Name text= 'Как это работает?' classes='blue' />
                            <Text text={frst} classes="blue"/>

                            <Text text={Txt} classes="blue" insert='•'/>
                        </div>
                        <div className="col-lg-6">
                            <img src={Pic} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
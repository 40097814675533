import React from 'react'
import Name from './Name'
import Text from './TextInner'
import lightbulb from '../img/lightbulb.svg'
import businesswoman from '../img/businesswoman.svg'
import mobility from '../img/mobility.svg'

const blocksData = [
    {
        img: lightbulb,
        text: 'Каталог более 4500 товаров'
    },
    {
        img: businesswoman,
        text: 'Доставка на следующий день'
    },
    {
        img: mobility,
        text: 'Регулярные акции от производителей'
    }
]
const Txt = [
    <b>Мы сделаем успешный бизнес доступным!</b>,
    'Лучшие оптовые цены на мясо, бакалею, овощи, фрукты, заморозку, товары у кассы, зоотовары и бытовую химию'
]

class Block extends React.Component{
    render(){
        return(
            <div className="block gray">
                <img src={this.props.imageS} alt="img" />
                <p>{this.props.text}</p>
            </div>
        )
    }
}

export default class Profits extends React.Component {
    render(){
  
        return (
            <div className='section profits'>
               <div className="container">
                    <div className='row'>
                         <div className='col-lg-4'>
                             <Name text = 'Преимущества' classes='red' />
                             <Text text={Txt} classes="blue"/>
                         </div>
                         <div className='col-lg-8'>
                             <div className="row blocks-prof">
                             {blocksData.map((item, ind) => (
                                    <div className="col-lg-4 blue">
                                        <Block key={ind} text={item.text} imageS={item.img} />
                                    </div>
                             ))}
                             </div>
                         </div>
                     </div>
               </div>
            </div>
        )
    }
}